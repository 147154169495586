export default {
    form: {
        startSurveyButton: "Empecemos",
        nextButton: "Siguiente",
        submitAnswerButton: "Enviar Respuesta",
        continueButton: "Continuar",
        finishButton: "Finalizar",
        headerPercentageCompletion: 'completado',
        headerQuestionsCompleted: 'preguntas',
        headerDefaultErrorMessage: 'Ups. Algo salió mal.',
        brandingPoweredBy: 'Desarrollado por',
        brandingPromoWhySurvey: '¿Por qué encuestar cuando puedes <b>{product}</b>?',
        brandingPromoGetStarted: '¡Empieza hoy mismo!',
        submissionPending: "Enviando tu voiceform. Por favor, no cierres la ventana del navegador.",
        submissionCanCloseWindow: "Tu voiceform ha sido enviado exitosamente. Ahora puedes cerrar la ventana 👍",
        mediaAttachmentOverlayCloseButton: "Cerrar",
        expiredFormHeader: "¡Gracias por pasar por aquí!",
        expiredFormMessage: "Parece que esta encuesta ha expirado. Por favor, contacta al creador del voiceform para informarle.",
        notPublishedFormHeader: "¡El voiceform no está publicado!",
        notPublishedFormMessage: "Parece que esta encuesta no ha sido publicada. Por favor, contacta al creador del voiceform para informarle.",
        notPublishedFormRetryButton: "Intentar de nuevo",
        errorFormHeader: "¡Ups!",
        errorFormMessage: "Algo pudo haber salido mal. Por favor, inténtalo de nuevo más tarde.",
        errorFormRetryButton: "Intentar de nuevo",
        emptyFormHeader: "¡Gracias por pasar por aquí!",
        emptyFormMessage: "¡Ups! Parece que este formulario está actualmente vacío y no tiene preguntas o campos para completar. Lamentamos los inconvenientes. Para solucionarlo, por favor contacta al creador de este formulario e infórmale sobre el problema. ¡Gracias por tu comprensión y cooperación!",

        restoreFormHeader: "¡Parece que tienes un formulario en progreso!",
        restoreFormMessage: "¿Te gustaría continuar donde lo dejaste?",
        restoreFormButton: "Continuar formulario",
        restoreFormButtonNew: "Comenzar de nuevo",

        audioPermissionHeader: "¿Te gustaría responder con voz?",
        audioPermissionMessage: "Hablar hará que responder sea rápido y fácil.",
        audioPermissionAcceptButton: "Sí, quiero",
        audioPermissionDenyButton: "No, gracias",
        audioPermissionErrorHeader: "El micrófono no está disponible",
        audioPermissionErrorMessage: "Por favor, verifica los permisos del navegador para habilitar el acceso al micrófono.",
        audioPermissionErrorButton: "De acuerdo",
        audioRecorderFailedUpload: "Error al subir la grabación",
        silenceDetectorError: "No puedo escucharte. Por favor, verifica las preferencias de tu micrófono. Consejo: Podría ser tu micrófono externo.",
        audioRecorderButtonRecord: "Grabar",
        audioRecorderButtonRecordMore: "Grabar más",
        voiceResponseSelectionMessage: "Selecciona cómo quieres responder...",
        voiceResponseSelectionOrDivider: "o",
        voiceResponseTextInputPlaceholder: "Escribe la respuesta",
        voiceResponseTextInputCharacterCounterMinMessage: "Por favor, ingresa al menos {min} caracteres",
        voiceResponseTextInputCharacterCounterCurrentNumberMessage: "{count} caracteres (mínimo {min} caracteres)",
        voiceResponseTextInputCharacterValidationMessage: "{count} caracteres (mínimo {min} caracteres)",

        // checkbox
        checkboxValidationTooFew: "Debes seleccionar al menos {min} opción(es)",
        checkboxValidationTooMany: "No debes seleccionar más de {max} opción(es)",
        checkboxNoneOfTheAboveOption: "Ninguna de las anteriores",
        checkboxInvalidAnswer: "Respuesta inválida.",

        // datepicker
        datePickerPlaceholder: "Selecciona la fecha",

        // dropdown
        // email
        emailLabel: "Correo electrónico",
        emailInvalid: "Correo electrónico no válido.",

        // file-upload
        fileUploadPluginNameCamera: "Cámara",
        fileUploadPluginNameCameraVideo: "Grabar video",
        fileUploadPluginScreenCast: "Captura",
        fileUploadVideoImportFilesDefault: "Graba o sube un video a través de:",
        fileUploadVideoImportFilesLocalFilesDisabled: "Selecciona una opción para grabar video:",
        fileUploadVideoImportFilesNoCamera: "Presiona un botón para grabar un video de la pantalla",
        fileUploadVideoImportFilesNoScreenCast: "Presiona un botón para grabar un video",
        fileUploadVideoImportFilesNoCameraAndScreenCast: "No hay opciones disponibles para grabar un video",

        // matrix
        matrixValidationMessage: "La respuesta es válida.",
        matrixRow: "Fila",
        matrixColumn: "Columna",
        matrixRowRequired: "La fila {rowTitle} es obligatoria.",
        matrixRadioGroup: "Grupo de opciones",
        matrixCheckboxGroup: "Grupo de casillas",
        matrixGroupRequired: "Para la fila {rowTitle}. {type} {groupTitle} es obligatorio.",
        matrixColumnRequired: "Para la fila {rowTitle}. La columna {columnTitle} es obligatoria.",
        matrixColumnInvalid: "Para la fila {rowTitle}. La columna {columnTitle} no es válida.",
        matrixRequired: "Requerido.",
        matrixNumericMustBeNumber: "Debe ser un número.",
        matrixNumericMustBeGreaterThenMin: "Debe ser mayor que {min}.",
        matrixNumericMustBeLessThenMax: "Debe ser menor que {max}.",
        matrixNumericMustBeInteger: "Debe ser un número entero.",
        matrixNumericInvalidNumber: "Número no válido.",

        // name
        nameLabel: "Nombre",
        nameInvalid: "Especifica nombre y apellido.",

        // nps
        npsNotLikely: "Nada probable",
        npsExtremelyLikely: "Extremadamente probable",        

        // numeric input
        numericInputRequired: "Requerido.",
        numericInputMustBeNumber: "Debe ser un número.",
        numericInputMustBeGreaterThenMin: "Debe ser mayor que {min}.",
        numericInputMustBeLessThenMax: "Debe ser menor que {max}.",
        numericInputMustBeInteger: "Debe ser un número entero.",
        numericInputInvalidNumber: "Número no válido.",
        numericInputPlaceholder: "Escribe un número",

        // phone
        phoneInvalid: "El número de teléfono no es válido.",
        phoneCountrySelectorLabel: "Código de país",
        phoneCountrySelectorError: "Elige un país",
        phoneNumberLabel: "Número de teléfono",
        phoneExample: "Ejemplo:",

        // boolean
        booleanYesLabel: "Sí",
        booleanNoLabel: "No",

        // questionStep
        questionStepAudioQuestionLabel: "Pregunta de audio",

        // errors
        invalidPhoneNumber: "{phone} no es un número de teléfono válido.",
        invalidEmail: "{email} no es una dirección de correo electrónico válida.",
        questionIsRequired: "La pregunta es obligatoria.",
        answerIsNotValid: "La respuesta no es válida.",
        unfinishedProbingDialogError: "Por favor, termina el diálogo.",
        cannotResumePartialResponse: "No se puede reanudar la respuesta parcial.",
        failedToSubmitForm: "Error al enviar el formulario. Verifica tu conexión a internet e inténtalo de nuevo.",
    }
}